<template>
  <Cursusbekijken />
</template>

<script>
import Cursusbekijken from "@/components/cursussen/Cursusbekijken";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Cursusbekijken,
  },
  metaInfo: {
    title: "Cursusbekijken | Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
