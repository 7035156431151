<template>
  <v-container>
    <div style="margin-bottom:3px;text-align: right">
      <v-btn
        rounded
        color="primary"
        @click="copyDataToLocalStorageAndNavigate()"
        >Kopieer Cursus</v-btn
      >
    </div>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1">Cursus informatie</v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title></v-card-title>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  dense
                  disabled
                  label="Artikelnummer"
                  outlined
                  v-model="form.artikelnummer"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Titel"
                  v-model="form.titel"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  type="number"
                  label="Aantal Personen"
                  v-model="form.aantalPersonen"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Aantal Uren"
                  type="number"
                  v-model="form.aantalUren"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  label="Aantal uren online"
                  type="number"
                  v-model="form.aantalUrenOnline"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  outlined
                  prefix="€"
                  type="number"
                  label="Prijs"
                  v-model="form.prijs"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  small-chips
                  deletable-chips
                  dense
                  multiple
                  outlined
                  :items="categorieen"
                  label="Categorie selecteren"
                  v-model="form.categories"
                  item-text="naam"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="vormen.flat()"
                  label="Cursusvorm selecteren"
                  v-model="form.vorm"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="certificaten.flat()"
                  label="Certificaat selecteren"
                  v-model="form.certificaat"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  dense
                  outlined
                  :items="tags"
                  label="URL tag selecteren"
                  v-model="form.tag"
                  item-text="naam"
                  item-value="naam"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="margin-left:14px">
                <v-switch
                  v-model="form.populair"
                  inset
                  :label="`Populair: ${form.populair ? 'Ja' : 'Nee'}`"
                ></v-switch>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="primary" @click="e13 = 2">Volgende</v-btn>
              <v-btn color="error" @click="goBack()" text>Annuleer</v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2"
          >Omschrijving en Cursusinformatie</v-stepper-step
        >

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="12">
                <label>Omschrijving en informatie *</label>
                <vue-editor
                  ref="editor1"
                  v-model="form.omschrijving"
                  class="custom-editor"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn color="warning" @click="e13 = 1" text> Vorige</v-btn>

              <v-btn style="margin:2px" color="error" @click="goBack()"
                >Annuleer</v-btn
              >
              <v-btn color="primary" @click="updateTraining()"
                >Cursus update</v-btn
              >
              <v-btn style="margin:2px" text color="warning" @click="e13 = 3"
                >Volgende >></v-btn
              >
            </div>
          </v-card>
        </v-stepper-content>
        <v-stepper-step step="3">Afbeeldingen</v-stepper-step>

        <v-stepper-content step="3">
          <v-card>
            <v-row>
              <v-col>
                <v-row>
                  <v-col
                    v-for="item in images"
                    :key="item.name"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-img
                      :src="`https://server.k-env.nl/folders/${item.loc}`"
                      alt="Item Afbeelding"
                      height="150"
                      width="350"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <template v-slot:error>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                          style="color: red;"
                        >
                          <v-icon>mdi-alert</v-icon> Kan afbeelding niet laden
                        </v-row>
                      </template>
                      <v-icon
                        color="red"
                        @click="deleteImage(item)"
                        class="delete-icon"
                        >mdi-close</v-icon
                      >
                    </v-img>
                  </v-col>
                </v-row>

                <v-form
                  ref="form"
                  @submit.prevent="submitForm"
                  v-if="images.length > 0"
                >
                  <v-file-input
                    v-model="files"
                    label="Selecteer een afbeelding"
                    multiple
                    :show-size="true"
                    @change="handleFileChange"
                  ></v-file-input>
                </v-form>
              </v-col>
            </v-row>
            <div style="margin-top: 5px">
              <v-btn style="margin:2px" color="error" @click="goBack()"
                >Annuleer</v-btn
              >
              <v-btn
                color="primary"
                @click="uploadFiles()"
                v-if="images.length > 0"
              >
                Afbeeldingen uploaden
              </v-btn>
              <v-btn color="warning" @click="e13 = 2" text>Vorige</v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apiFolder from "../api/apiFolders";
import apiCategorie from "../api/apiCategorie";
import apiCertificaat from "../api/apiCertificaat";
import { Quill } from "vue-quill-editor";
import apiTraining from "../api/apiTraining";
import { VueEditor } from "vue2-editor";
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles +=
    ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
    fontName +
    "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
    fontName +
    "]::before {" +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    "}" +
    ".ql-font-" +
    fontName +
    "{" +
    " font-family: '" +
    font +
    "', sans-serif;" +
    "}";
});

const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

const sizeStyle = Quill.import("attributors/style/size");
sizeStyle.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "30px",
  "32px",
  "36px",
];
Quill.register(sizeStyle, true);

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      showInstructionsDialog: false,
      certificaten: [],
      categorieen: [],
      tags: ["ehbo", "bhv", "overig"],
      vormen: ["Klassikaal", "Online + klassikaal", "Online"],
      e13: 1,
      images: [],
      files: [],
      form: {
        tag: "",
        populair: false,
        titel: "",
        categories: [],
        aantalPersonen: "",
        aantalUren: "",
        prijs: null,
        certificaat: "",
        vorm: "",
        status: "",
        omschrijving: "",
        cursusinformatie: "",
        cursusDag: "",
        artikelnummer: "",
      },
      statussen: [
        { name: "Actief", id: "actief" },
        { name: "Inactief", id: "inactief" },
      ],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["bold", "italic", "underline"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ font: fonts.whitelist }],
        [
          {
            size: [
              false,
              "10px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "24px",
              "30px",
              "32px",
              "36px",
            ],
          },
        ],
        [{ align: [] }],

        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["clean"],
      ],
    };
  },
  async mounted() {
    await this.fetchTraining();
    this.fetchCertificaat();
    this.fetchCategorie();
    this.fetchImages();
  },

  methods: {
    handleFileChange(files) {
      this.files = files;
    },

    fetchImages() {
      const folderName = `afbeeldingen/${this.form.artikelnummer}`;
      apiFolder.fetchImages(folderName).then((data) => {
        this.images = data;
      })
    },

    resetForm() {
      this.form.titel = "";
      this.form.aantalPersonen = "";
      this.form.aantalUren = "";
      this.form.prijs = null;
      this.form.certificaat = "";
      this.form.vorm = "";
      this.form.status = "";
      this.form.omschrijving = "";
      this.form.cursusinformatie = "";
      this.form.cursusDag = "";
      this.form.artikelnummer = "";
    },
    fetchCategorie() {
      apiCategorie.haalCategorieOp().then((data) => {
        this.categorieen = data;
      });
    },
    fetchCertificaat() {
      apiCertificaat.haalCertificaat().then((data) => {
        this.certificaten = data;
      });
    },
    copyDataToLocalStorageAndNavigate() {
      localStorage.setItem("formData", JSON.stringify(this.form));
      this.$router.push("/Cursustoevoegen");
    },
    async deleteImage() {
      try {
        const folderName = `afbeeldingen/${this.form.artikelnummer}`;
        await apiFolder.deleteFile(folderName);
        this.fetchImages();
        await this.fetchTraining();
        this.$swal.fire(
          "Verwijderd!",
          "De afbeelding is verwijderd.",
          "success"
        );
      } catch (error) {
        console.error("Kon afbeelding niet verwijderen:", error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    async fetchTraining() {
      try {
        const data = await apiTraining.getSingleTraining(this.$route.params.id);
        this.form = data;
      } catch (error) {
        console.error("Could not load course:", error);
      }
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB"); // UK format: DD/MM/YYYY
    },
    goBack() {
      this.$router.push("/Cursussenbeheren");
    },
    nextStep() {
      this.currentStep = "complete";
    },
    async updateTraining() {
      try {
        const id = this.$route.params.id;
        const response = await apiTraining.updateTraining(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het updaten is gelukt!", "success");
        this.resetForm();
        this.fetchTraining();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.custom-text-field {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.custom-editor .ql-container {
  font-family: "Arial", sans-serif;
  font-size: 16px;
}

.ql-picker-item[data-value="10px"]::before,
.ql-picker-label[data-value="10px"]::before {
  content: "10px" !important;
}

.ql-picker-item[data-value="12px"]::before,
.ql-picker-label[data-value="12px"]::before {
  content: "12px" !important;
}

.ql-picker-item[data-value="14px"]::before,
.ql-picker-label[data-value="14px"]::before {
  content: "14px" !important;
}

.ql-picker-item[data-value="16px"]::before,
.ql-picker-label[data-value="16px"]::before {
  content: "16px" !important;
}

.ql-picker-item[data-value="18px"]::before,
.ql-picker-label[data-value="18px"]::before {
  content: "18px" !important;
}

.ql-picker-item[data-value="20px"]::before,
.ql-picker-label[data-value="20px"]::before {
  content: "20px" !important;
}

.ql-picker-item[data-value="24px"]::before,
.ql-picker-label[data-value="24px"]::before {
  content: "24px" !important;
}

.ql-picker-item[data-value="30px"]::before,
.ql-picker-label[data-value="30px"]::before {
  content: "30px" !important;
}

.ql-picker-item[data-value="32px"]::before,
.ql-picker-label[data-value="32px"]::before {
  content: "32px" !important;
}

.ql-picker-item[data-value="36px"]::before,
.ql-picker-label[data-value="36px"]::before {
  content: "36px" !important;
}
</style>
